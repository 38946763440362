const popup = document.querySelector("#popupModal")
setTimeout(() => { 
  popup.showModal() 
  document.cookie = "seen_popup=true"
}, 1000);

const closePopupBtns = document.querySelectorAll(".closePopup");
closePopupBtns.forEach((btn) => {
  btn.addEventListener("click", () => {
    popup.close();
  }, { once: true });
});

const popupRestricted = document.querySelector('#popup-restricted')
document.addEventListener('click', function(event) {
  // Check if the clicked element is outside the popup modal
  if (!popupRestricted.contains(event.target)) {
    popup.close();
  }
});
